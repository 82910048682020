.imgbox {
  width: 100%;
  height: 65vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.imgbox::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.center {
  position: absolute;
  text-align: center;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fefefe;
  z-index: 2;
}

.head {
  font-size: 50px;
  color: #fefefe;
  font-weight: bold;
  margin-bottom: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.subHead {
  font-size: 19px;
  color: #fefefe;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 1080px) {
  .imgbox {
    height: 50vh;
  }
  .head {
    font-size: 40px;
  }
  .center {
    top: 37%;
  }
}

@media screen and (max-width: 900px) {
  .imgbox {
    height: 40vh;
  }
  .head {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .subHead {
    font-size: 15px;
  }
  .center {
    top: 32%;
  }
}

@media screen and (max-width: 600px) {
  .imgbox {
    height: 30vh;
  }
  .head {
    font-size: 26px;
  }
  .subHead {
    font-size: 14px;
  }
  .center {
    top: 24%;
  }
}

@media screen and (max-width: 450px) {
  .imgbox {
    height: 25vh;
  }
  .head {
    margin-top: 100px;
    font-size: 30px;
  }
  .subHead {
    font-size: 14px;
  }
  .center {
    top: 20%;
  }
}
