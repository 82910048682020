.imgbox {
  height: 100vh;
  position: relative;
  overflow: hidden;
  width: 100%;
  background-size: cover;
  background-position: center;
  transition: background-image 1.2s ease-in-out;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-shadow: inset 0 0 200px rgba(0, 0, 0, 0.7);
}

@media screen and (max-width: 1024px) {
  .imgbox {
    height: 80vh;
  }
}

@media screen and (max-width: 768px) {
  .imgbox {
    height: 60vh;
  }
}

@media screen and (max-width: 480px) {
  .imgbox {
    height: 50vh;
  }
}

.center {
  width: 100%;
  text-align: center;
  z-index: 1;
  padding: 20px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(8px);
  border-radius: 0;
  animation: fadeIn 2s ease-in-out;
  box-shadow: 0 -10px 30px rgba(0, 0, 0, 0.3);
  position: absolute;
  bottom: 0;
}

.text {
  font-size: 3.5rem;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  padding: 0 20px;
  white-space: nowrap;
}

.subText {
  font-size: 1.2rem;
  color: #f0f0f0;
  font-weight: 500;
  margin-bottom: 2px;
}

.carouselControl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  transition: transform 0.2s ease-in-out;
}

.carouselControl:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
}

.carousel-control-prev {
  left: 20px;
}

.carousel-control-next {
  right: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 768px) {
  .text {
    font-size: 2rem; 
    font-weight: 600;
    padding: 0 10px;
  }
  .subText {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 480px) {
  .text {
    font-size: 1.5rem; 
    padding: 0 10px;
  }
  .subText {
    font-size: 0.9rem;
  }

  .carouselControl {
    width: 40px;
    height: 40px;
  }

  .carouselControl:hover {
    transform: scale(1.2); 
  }
}
