.heading {
  font-size: 42px;
  font-weight: 600;
  color: #9951c4;
  margin-top: 77px;
  margin-bottom: 20px;
  text-align: center;
  line-height: 1.3;
}

.subHeading {
  font-size: 35px;
  font-weight: 600;
  color: #2e2d26;
  text-align: center;
  line-height: 1.4;
}

.content {
  font-size: 20px;
  color: #6f7473;
  margin-top: 14px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 70px;
  text-align: center;
  line-height: 1.6;
}

.about {
  font-size: 60px;
  font-weight: 400;
  color: #9951c4;
  text-align: center;
  margin-bottom: -14px;
  margin-top: 100px;
  line-height: 1.3;
}

.creativity {
  font-size: 32px;
  font-weight: 600;
  color: #4f4c86;
  text-align: center;
}

.contn {
  font-size: 20px;
  color: #6f7473;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .heading {
    font-size: 30px;
    margin-top: 40px;
  }

  .subHeading {
    font-size: 30px;
    margin-top: 20px;
  }

  .content {
    width: 90%;
    font-size: 16px;
    margin-bottom: 40px;
  }

  .about {
    font-size: 24px;
    margin-top: 60px;
  }

  .creativity {
    font-size: 16px;
  }

  .contn {
    font-size: 15px;
    color: #6f7473;
    text-align: center;
  }

  .imgContain {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
  }

  .img1 {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 425px) {
  .heading {
    font-size: 28px;
    margin-top: 40px;
  }

  .subHeading {
    font-size: 32px;
    margin-top: 20px;
  }

  .content {
    width: 90%;
    font-size: 18px;
    margin-bottom: 40px;
  }

  .about {
    font-size: 30px;
    margin-top: 60px;
  }

  .creativity {
    font-size: 24px;
  }

  .imgContain {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
  }

  .img1 {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
  }

  .contain {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 992px) {
  .contain {
    padding-left: 0px;
  }

  .imgContain {
    margin-left: -30px;
  }

  .about {
    font-size: 44px;
  }

  .creativity {
    font-size: 28px;
  }
}

@media screen and (max-width: 1200px) {
  .contain {
    padding-left: 40px;
  }

  .imgContain {
    margin-left: -60px;
  }
}
