.img {
    width: 60%;
    height: 90%;
    object-fit: cover;
}

.heading {
    font-size: 35px;
    font-weight: 600;
    color: #9951c4;
    text-align: left;
}

.subHeading {
    font-size: 30px;
    font-weight: bold;
    text-align: left;
    width: 400px;
    margin-bottom: 20px;
}

.content {
    font-size: 21px;
    text-align: left;
    color: #1f354e;
    width: 90%;
    text-align: justify;
    margin-bottom: 30px;
}

.btn {
    width: 272px;
    height: 64px;
    font-size: 14px;
}

.text {
    margin-left: 350px;
    margin-top: 50px;
}


@media screen and (max-width: 1200px) {
    .text {
        margin-top: 20px;
        margin-left: 0; 
    }
}

@media screen and (max-width: 1100px) {
    .heading {
        font-size: 25px;
    }
    .subHeading {
        font-size: 28px;
        width: 300px;
    }
    .btn {
        height: 50px;
        width: 200px;
    }
    .text {
        margin-left: 20px; 
    }
}

@media screen and (max-width: 950px) {
    .imgDiv {
        margin-top: 100px;
    }
    .text {
        margin-left: 0;
    }
}

@media screen and (max-width: 768px) {
    .imgDiv {
        margin-top: 0px;
    }
    .heading {
        font-size: 20px;
    }
    .subHeading {
        font-size: 22px;
        margin-bottom: 22px;
    }
    .btn {
        width: 100%;
        font-size: 16px;
    }
    .content {
        font-size: 18px;
    }
    .text {
        margin-left: 20px;
        margin-top: 20px;
    }
}

@media screen and (max-width: 500px) {
    .heading {
        font-size: 22px;
    }
    .subHeading {
        font-size: 20px;
        margin-bottom: 20px;
        width: 70%;
    }
    .content {
        margin-bottom: 23px;
        font-size: 16px;
    }
    .btn {
        width: 100%;
        height: 50px;
        font-size: 14px;
    }
    .text {
        margin-left: 20px;
        margin-top: 10px;
    }
}

@media screen and (max-width: 355px) {
    .subHeading {
        width: 100%;
    }
    .heading {
        font-size: 16px;
    }
    .content {
        font-size: 14px;
    }
    .btn {
        width: 100%;
        height: 45px;
        font-size: 12px;
    }
    .text {
        margin-left: 0;
        margin-top: 10px;
    }
}

.container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.imgDiv {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
}
