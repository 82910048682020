.imgbox {
  position: relative;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.imgbox::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.overlay {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 2;
}

.title {
  font-size: 1.6rem;
  color: #0a0a0a;
  margin-bottom: 150px;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  padding: 10px 20px;
  border-radius: 8px;
  z-index: 3;
}

.head {
  font-size: 3.5rem;
  color: #fff;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
  white-space: normal;
  line-height: 1.2;
  max-width: 90%;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  z-index: 3;
}

.description {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 15px;
  border-radius: 5px;
  font-size: 1.3rem;
  color: #fff;
  margin-bottom: 30px;
  text-align: left;
  line-height: 1.2;
  z-index: 3;
}

.formContainer {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
  width: 80%;
  max-width: 500px;
  margin: 0 auto;
  z-index: 3;
}

.formContainer h2 {
  margin-bottom: 20px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.formGroup input,
.formGroup select,
.formGroup textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.inputField {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;
}

/* break */

.about {
  display: flex;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
  gap: 60px;
  padding: 20px 10px;
  position: relative;
}

.abouttitle {
  font-size: 2rem;
}

.about h4 {
  font-size: 1.5rem;
  white-space: nowrap;
  margin-top: 5px;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-right: 40px;
  padding-left: 60px;
}

.content h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #333;
}

.content h1 {
  font-size: 1.5rem;
  line-height: 1;
  margin-top: 10px;
  color: #666;
  font-weight: 500;
}

.content p {
  font-size: 2.5rem;
  margin-top: 10px;
  min-width: 40%;
  max-width: 20%;
  line-height: 1.2;
}

.abouttitle {
  font-size: 2.5rem;
  color: #e9ca31;
  font-weight: bold;
}

.content span {
  white-space: nowrap;
  overflow: hidden;
}

.image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.highlights {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-center;
  align-items: flex-start;
  padding: 20px;
  text-align: left;
}

.highlight {
  margin-bottom: 20px;
}

.highlight h3 {
  font-size: 1.8rem;
  margin: 0 0 10px 0;
  color: #031450;
  font-weight: bold;
}

.highlight p {
  font-size: 1.4rem;
  line-height: 1;
  color: #333;
  margin: 0;
}

/* break */

.licenses {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px 10px;
}

.licenses h1 {
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}

.licenses h2 {
  color: #00796b;
  font-size: 42px;
  margin-top: 5px;
}

.licenses h3 {
  font-size: 23px;
  margin-top: 5px;
  margin-bottom: 20px;
  color: #031450;
  /* width: 100%;
  max-width: 500px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;
  word-wrap: break-word;
  text-align: center; */
}

.licenses h4 {
  color: #031450;
  font-size: 26px;
}

.licensesGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.licenseCard {
  background: linear-gradient(135deg, #f2f6ff, #e3f2f1);
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.licenseCard p {
  font-size: 20px;
  color: #333;
  line-height: 1.2;
  margin-top: 15px;
}

/* break */

.process {
  padding: 20px;
  /* background-color: #f9f9f9; */
  border-radius: 10px;
}

.stepsList {
  list-style-type: none;
  padding: 0;
  width: 80%;
  max-width: 900px;
  margin: 0 auto;
}

.stepsList li {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.stepsList li:last-child {
  border-bottom: none;
}

.process h1 {
  font-size: 38px;
}

.process h2 {
  color: #ae445a;
  font-size: 20px;
  max-width: 600px;
  line-height: 1.2;
  word-wrap: break-word;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
}

.stepItem {
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
}

.stepHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  background-color: #031450;
  color: #fff;
  font-size: 18px;
}

.stepHeader:hover {
  background-color: #17181a;
}

.toggleIcon {
  font-size: 20px;
}

.stepContent {
  padding: 10px;
  background-color: #f8f8f8;
  display: none;
}

.stepContent p {
  margin: 0;
  font-size: 22px;
}

/* break */

.reviews {
  padding: 2rem;
  text-align: center;
  margin-bottom: 80px;
  margin-top: 80px;
}

.reviews h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.reviewContent {
  font-size: 20px;
  color: #333;
}

.reviewContent strong {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #007bff;
}

.reviews ul li {
  font-size: 16px;
  margin: 10px 0;
}

/* break */

.whyCard {
  background: linear-gradient(135deg, #e0c3fc, #8e7cc3);
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 450px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.whyGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
}

.whyChooseUs h4 {
  font-size: 32px;
  color: #031450;
}

.whyChooseUs h2 {
  font-size: 22px;
  color: #031450;
  margin-top: 20px;
  margin-bottom: 40px;
}

.whyChooseUs p {
  font-size: 18px;
  font-weight: 500;
  color: #fff6f6;
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 10px;
}

/* break */

.extra {
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px 10px;
  margin-top: 100px;
}

.extra h1 {
  font-size: 36px;
  max-width: 50%;
  word-wrap: break-word;
  text-align: center;
  margin: 0 auto;
}

.extra h2 {
  font-size: 20px;
  margin-top: 20px;
  line-height: 1.4;
}
/* break */

.row {
  display: flex;
  gap: 10px;
}

.col-6 {
  flex: 1;
}

.submitButton {
  padding: 10px 20px;
  background-color: #0056b3;
  color: #fff;
  border: none;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #003f7f;
}

.submitButton {
  padding: 10px 20px;
  background-color: #0056b3;
  color: #fff;
  border: none;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #003f7f;
}

.banner {
  background-color: #2f3b44;
  color: white;
  text-align: center;
  padding: 50px;
}

.about,
.licenses,
.process,
.whyChooseUs,
.reviews {
  margin-top: 100px;
  padding: 40px;
  /* background-color: #f9f9f9; */
  /* border-radius: 10px; */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

h2 {
  font-size: 24px;
  color: #2f3b44;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  font-size: 18px;
  margin: 10px 0;
}

@media screen and (max-width: 600px) {
  .imgbox {
    min-height: 100vh;
  }

  .formContainer {
    width: 90%;
    max-width: 90%;
    margin-top: 10px ;
  }

  .title,
  .head,
  .description {
    font-size: 1rem;
    padding: 5px 10px;
  }

  .about {
    flex-direction: column;
    padding: 10px;
    gap: 20px;
  }

  .about .content {
    padding-right: 20px;
    padding-left: 20px;
  }

  .about .abouttitle {
    font-size: 24px;
  }

  .image img {
    width: 100%;
  }

  .reviews ul li {
    font-size: 14px;
  }

  .process h1 {
    font-size: 32px;
  }

  .process h2 {
    font-size: 18px;
  }

  .whyChooseUs h4 {
    font-size: 28px;
  }

  .extra h1 {
    font-size: 28px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1200px) {
  .about {
    flex-direction: row;
    padding: 20px;
    gap: 40px;
  }

  .about .content {
    padding-left: 40px;
    padding-right: 40px;
  }

  .formContainer {
    width: 70%;
  }

  .reviews ul li {
    font-size: 18px;
  }

  .process h1 {
    font-size: 36px;
  }

  .process h2 {
    font-size: 20px;
  }

  .whyChooseUs h4 {
    font-size: 30px;
  }

  .extra h1 {
    font-size: 32px;
  }
}

@media screen and (min-width: 1200px) {
  .about {
    flex-direction: row;
    padding: 40px;
    gap: 60px;
  }

  .about .content {
    padding-left: 60px;
    padding-right: 60px;
  }

  .formContainer {
    width: 50%;
  }

  .reviews ul li {
    font-size: 20px;
  }

  .process h1 {
    font-size: 40px;
  }

  .process h2 {
    font-size: 22px;
  }

  .whyChooseUs h4 {
    font-size: 32px;
  }

  .extra h1 {
    font-size: 36px;
  }
}

@media screen and (max-width: 768px) {
  .imgbox {
    min-height: 60vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title,
  .head,
  .description {
    font-size: 1.2rem;
    padding: 10px;
    text-align: center;
    width: 90%;
    margin: 0 auto;
  }

  .formContainer {
    width: 90%;
    max-width: 600px;
    margin: 0 auto;
    padding: 15px;
  }

  .about {
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }

  .about .content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .image img {
    width: 100%;
  }

  .reviews ul li {
    font-size: 16px;
  }

  .process h1 {
    font-size: 30px;
  }

  .process h2 {
    font-size: 18px;
  }

  .whyChooseUs h4 {
    font-size: 28px;
  }

  .extra h1 {
    font-size: 30px;
  }

  .licensesGrid {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
  }
}
