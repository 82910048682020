.contain {
    padding-top: 146px;
    padding-bottom: 145px;
}

.mainHead {
    font-size: 60px;
    font-weight: bold;
    color: #222a41;
    margin-bottom: 10px;
}

.mainContent {
    font-size: 23px;
    font-weight: 600;
    color: #4f4c86;
    padding-bottom: 30px;
    line-height: 1.6; 
    max-width: 800px; 
    margin: 0 auto; 
}

.box {
    background-color: rgba(79, 76, 134, 0.1);
    border: solid 2px rgba(220, 216, 226, 0.5);
    border-radius: 10px;
    padding: 40px 27px 55.4px 28px;
    margin-top: 20px;
}


.head{
    font-size: 22px;
    font-weight: bold;
    color: #070707;
    margin-bottom: 20px;
    margin-top: 25px;
}

.content {
    font-size: 21px;
    color: #6f7473;
    line-height: 1.3; 
    max-width: 900px; 

}

@media screen and (max-width: 992px) {
    .mainHead {
        font-size: 40px;
    }
    
    .mainContent {
        font-size: 20px;
        padding-bottom: 70px;
    }

    .contain {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .box {
        padding: 20px 10px 20px;
    }

    .head {
        margin-bottom: 15px;
        margin-top: 15px;
    }

    .content {
        font-size: 18px;
    }
}

@media screen and (max-width: 768px) {
    .box {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 650px) {
    .contain {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .mainHead {
        font-size: 30px;
    }

    .mainContent {
        font-size: 18px;
    }

    .head {
        font-size: 20px;
    }

    .content {
        font-size: 16px;
    }
}
