.box {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
  padding: 40px 20px;
  margin-top: 24px;
  height: 400px; 
  border-radius: 10px;
  transition: all 0.3s ease;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.box:hover {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 4px 12px 0px,
    rgba(14, 30, 37, 0.32) 0px 4px 20px 0px;
  transform: scale(1.02);
  transition: all 0.3s ease;
}

.heading {
  font-size: 26px;
  color: #222a41;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
}

.text {
  font-size: 18px;
  color: #2a3747;
  line-height: 1.6;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  flex-grow: 1;
}

.to {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.to:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 1000px) {
  .heading {
    font-size: 20px;
  }
  .text {
    font-size: 16px;
  }
}

@media screen and (max-width: 992px) {
  .box {
    height: 400px; 
  }
}

@media screen and (max-width: 768px) {
  .box {
    padding-right: 20px;
    padding-left: 20px;
    height: 400px;
  }
}

@media screen and (max-width: 410px) {
  .heading {
    font-size: 18px;
  }
  .text {
    font-size: 14px;
  }
}
