.contain {
  margin-top: 120px;
  margin-bottom: 120px;
  padding: 0 15px;
}

.heading {
  font-size: 50px;
  font-weight: 600;
  color: #9951c4;
  margin-bottom: 3px;
  text-align: center;
  line-height: 1.2;
  letter-spacing: 1px;
  transition: color 0.3s ease;
}

.heading_content {
  font-size: 24px;
  font-weight: 400;
  color: #131314;
  line-height: 1.8;
  text-align: center;
  margin-bottom: 30px;
  max-width: 900px;
  margin: 0 auto;
  letter-spacing: 0.5px;
}

.subHead {
  font-size: 34px;
  font-weight: 600;
  color: #9951c4;
  margin-bottom: 2px;
  margin-top: 20px;
  text-align: left;
}

.contentImg {
  width: 90%;
  border-radius: 15px;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-right: 30px;
  margin-top: 20px;
}

.contentImg:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.justify {
  text-align: justify;
}

.explanation p {
  font-size: 20px;
  color: #444554;
  line-height: 1.6;
  margin-bottom: 5px;
  text-align: left;
  letter-spacing: 0.5px;
}

.rowSpacing {
  margin-top: 100px;
  margin-bottom: 80px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modalContent {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modalTitle {
  font-size: 26px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.modalField {
  margin-bottom: 15px;
}

.modalField label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  color: #555;
}

.modalInput {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.modalInput::placeholder {
  color: #aaa;
}

.modalField input {
  width: 100%;
  padding: 8px;
  margin: 4px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modalField input[type="text"] {
  flex: 1;
}

.submitButton {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  margin-top: 10px;
}

.submitButton:hover {
  background-color: #45a049;
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 28px;
  color: #333;
  background: none;
  border: none;
  cursor: pointer;
}

.closeIcon:hover {
  color: #f44336;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  z-index: 1050;
}

.popupContent {
  text-align: center;
}

.closeBtn {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal {
  z-index: 1000;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1049;
}

@media screen and (max-width: 1200px) {
  .modalTitle {
    font-size: 24px;
  }

  .heading {
    font-size: 54px;
  }

  .heading_content {
    font-size: 22px;
  }

  .subHead {
    font-size: 28px;
  }
}

@media screen and (max-width: 992px) {
  .heading {
    font-size: 40px;
  }

  .heading_content {
    font-size: 20px;
  }

  .subHead {
    font-size: 26px;
  }

  .contentImg {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .heading {
    font-size: 36px;
  }

  .heading_content {
    font-size: 20px;
  }

  .subHead {
    font-size: 24px;
  }
}

@media screen and (max-width: 576px) {
  .heading {
    font-size: 32px;
  }

  .heading_content {
    font-size: 18px;
  }

  .subHead {
    font-size: 22px;
  }
}
