.container {
  font-family: "Arial", sans-serif;
  padding: 20px;
  background-color: #f9f9f9;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.headerImage {
  width: 250px;
  height: auto;
  margin-right: 20px;
}

.section {
  background: white;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.section a {
  color: #0066cc;
  text-decoration: none;
}

.section a:hover {
  text-decoration: underline;
}

.options {
  text-align: center;
}

.options h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.card {
  display: inline-block;
  width: 30%;
  background: white;
  margin: 10px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.card ul {
  list-style-type: none;
  padding: 0;
}

.card ul li {
  margin-bottom: 10px;
  line-height: 1.5;
}

.card h3 {
  color: #333;
}

.popular {
  border: 2px solid #0066cc;
  position: relative;
}

.popular::before {
  content: "MOST POPULAR";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background: #0066cc;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 4px;
}

.discount {
  color: #e60000;
  font-weight: bold;
}

.discount del {
  color: #999;
  margin-left: 5px;
}

.section {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  color: #333333;
}

.introText {
  font-size: 18px;
  margin-bottom: 20px;
}

.detailsList {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
}

.detailsList li {
  font-size: 16px;
  margin-bottom: 10px;
}

.estimateText {
  margin-bottom: 20px;
  font-size: 16px;
  color: #555555;
}

.contactText {
  font-size: 16px;
  margin-bottom: 20px;
}

.emailLink,
.whatsappLink {
  color: #0066cc;
  text-decoration: none;
  font-weight: bold;
}

.emailLink:hover,
.whatsappLink:hover {
  text-decoration: underline;
}

.signatureText {
  font-size: 16px;
  margin-top: 20px;
}

.signatureName {
  font-size: 18px;
  font-weight: bold;
}

.signatureRole {
  font-size: 16px;
  color: #555555;
}

.firstSection {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 40px 20px;
  margin: 20px auto;
  max-width: 800px;
  text-align: left;
  font-family: "Arial", sans-serif;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.logo {
  width: 250px;
  height: auto;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin: 0;
}

.content p {
  font-size: 19px;
  line-height: 1.6;
  color: #555555;
  margin-bottom: 20px;
}

.details {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.details li {
  font-size: 13px;
  color: #333333;
  margin-bottom: 10px;
}

.link {
  color: #0066cc;
  text-decoration: none;
  font-weight: bold;
}

.link:hover {
  text-decoration: underline;
}

.signature {
  font-size: 16px;
  margin: 20px 0 5px;
}

.signatureName {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}

.signatureRole {
  font-size: 16px;
  color: #666666;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.cardTitle {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 15px;
}

.cardList {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.cardList li {
  font-size: 16px;
  color: #555555;
  margin-bottom: 10px;
}

.cardPrice {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
}

.discountedPrice {
  color: #e74c3c;
}

.originalPrice {
  color: #999999;
  text-decoration: line-through;
  margin-left: 10px;
}

.selectButton {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #0066cc;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
}

.selectButton:hover {
  background-color: #005bb5;
}

.popular {
  background-color: #f1faff;
  border: 2px solid #0066cc;
}

.cardsSection {
  padding: 2rem;
  background-color: #f9f9f9;
}

.optionsTitle {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.optionsDescription {
  text-align: center;
  margin-bottom: 2rem;
  color: #555;
}

.cardContainer {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.card {
  width: 250px;
  height: 520px;
  padding: 1.5rem;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.cardTitle {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.cardList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
}

.cardList li {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: #666;
}

.cardPrice {
  font-size: 1.2rem;
  font-weight: bold;
  color: #222;
}

.discountedPrice {
  color: #28a745;
  font-size: 1.4rem;
}

.originalPrice {
  color: #999;
  text-decoration: line-through;
  font-size: 1rem;
}

.selectButton {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.selectButton:hover {
  background-color: #0056b3;
}

.popular {
  border: 2px solid #ffc107;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup p {
  font-size: 22px;
}

.popupContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 90%; 
  max-width: 500px;
  font-size: 22px;
}

.closeButton {
  background-color: #007bff;
  color: white;
  padding: 5px 10px; 
  font-size: 15px; 
  border: none;
  border-radius: 3px; 
  cursor: pointer;
  margin-top: 5px;
}

.closeButton:hover {
  background-color: #0056b3;
}

.checkIcon {
  color: #28a745; 
  font-size: 40px;
  margin: 10px 0; 
}
