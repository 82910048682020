.imgbox {
  position: relative;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.overlay {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.head {
    font-size: 3.5rem;
    color: #fff;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: left;
    white-space: normal; 
    line-height: 1;
    max-width: 70%; 
  }
  

.description {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 15px;
  border-radius: 5px;
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 30px;
  text-align: left;
}

.ctaButton {
  padding: 10px 20px;
  background-color: #0056b3;
  color: #fff;
  border: none;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  float: left;
}

.ctaButton:hover {
  background-color: #003f7f;
}

.formContainer {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.formContainer h2 {
  margin-bottom: 20px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.formGroup input,
.formGroup select,
.formGroup textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.inputField {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;
}

.row {
  display: flex;
  gap: 10px;
}

.col-6 {
  flex: 1;
}

.submitButton {
  padding: 10px 20px;
  background-color: #0056b3;
  color: #fff;
  border: none;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #003f7f;
}

.submitButton {
  padding: 10px 20px;
  background-color: #0056b3;
  color: #fff;
  border: none;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #003f7f;
}

@media (max-width: 1200px) {
  .head {
    font-size: 2.5rem;
  }
  .description {
    font-size: 1rem;
  }
  .ctaButton {
    font-size: 0.9rem;
    padding: 8px 18px;
  }
}

@media (max-width: 992px) {
  .imgbox {
    min-height: 80vh;
  }
  .head {
    font-size: 2rem;
  }
  .description {
    font-size: 1rem;
  }
  .ctaButton {
    font-size: 0.9rem;
    padding: 8px 18px;
  }
}

@media (max-width: 768px) {
  .imgbox {
    min-height: 70vh;
  }
  .head {
    font-size: 1.8rem;
  }
  .description {
    font-size: 0.9rem;
  }
  .ctaButton {
    font-size: 0.8rem;
    padding: 8px 15px;
  }
}

@media (max-width: 576px) {
  .imgbox {
    min-height: 60vh;
  }
  .head {
    margin-top: 30px;
    font-size: 1.6rem;
  }
  .description {
    font-size: 0.8rem;
  }
  .ctaButton {
    font-size: 0.8rem;
    padding: 8px 15px;
  }
  .formContainer {
    padding: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .formGroup input,
  .formGroup select,
  .formGroup textarea {
    font-size: 0.9rem;
  }
  .submitButton {
    font-size: 0.8rem;
    padding: 8px 15px;
  }
}
