.contain {
  padding-bottom: 100px;
}

.row {
  margin-top: 100px !important;
}

.head {
  font-size: 36px;
  font-weight: bold;
  color: #4f4c86;
  text-align: left;
  margin-top: 50px;
  margin-left: 20px;
  transition: color 0.3s;
}

.head:hover {
  color: #2e2d26;
}

.content {
  font-size: 20px;
  color: #0f0a18;
  opacity: 0.7;
  text-align: left;
  margin-left: 20px;
}

.img {
  width: 60%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  border-radius: 15px;
}

.img1 {
  width: 50%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  border-radius: 15px;
}

.head1 {
  font-size: 36px;
  font-weight: bold;
  color: #4f4c86;
  text-align: left;
  margin-top: 50px;
  transition: color 0.3s;
}

.content1 {
  font-size: 20px;
  color: #0f0a18;
  opacity: 0.7;
  text-align: left;
  margin-right: 100px;
}

@media screen and (max-width: 992px) {
  .head {
    margin-left: 0px;
    margin-top: 90px;
  }
  .content {
    margin-left: 0px;
  }
  .img {
    margin-left: -60px;
  }
  .img1 {
    margin-left: 40px;
  }
  .head1 {
    margin-top: 90px;
  }
  .content1 {
    margin-right: 0px;
  }
  .row {
    margin-top: 80px !important;
  }
  .contain {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 768px) {
  .img,
  .img1 {
    margin-left: 0px;
  }
  .head,
  .head1 {
    margin-top: 50px;
  }
}

@media screen and (max-width: 768px) {
  .head,
  .head1 {
    font-size: 26px;
    margin-top: 30px;
  }
  .row {
    margin-top: 50px !important;
  }
}
