.contain {
    max-width: 1200px;
    margin: 50px auto;
    padding: 0 20px;
  }
  
  .Section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .section1,
  .section2 {
    width: 48%;
  }
  
  .section1,
  .section2 {
    text-align: left;
  }
  
  .heading1,
  .heading2 {
    font-size: 36px;
    margin-bottom: 10px;
    color: #031450;
    font-weight: 700;
  }
  
  .heading_content {
    font-size: 22px;
    margin-bottom: 19px;
  }
  
  .licenseList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4px;
    color: #5a1064;
  }
  
  .licenseItem {
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 8px;
  }
  
  .calculateButton {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .calculateButton:hover {
    background-color: #0056b3;
  }
  
  .bannerImage {
    width: 100%;
    margin-top: 20px;
  }
  
  .section1 {
    padding: 20px;
    margin-top: 10px;
  }
  
  .licenseList {
    display: flex;
    flex-direction: column;
  }
  
  .licenseItem {
    background-color: #f4f4f4;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .licenseItem:hover {
    background-color: #e2e2e2;
  }
  
  .popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popupContent {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    text-align: center;
  }
  
  .closePopupButton {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .closePopupButton:hover {
    background-color: #0056b3;
  }
  
  /* License Types and Activities */
  .licenseSection {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 40px;
    background-color: #f9f9f9;
    border-radius: 10px;
    align-items: flex-start;
  }
  
  .stepsContainer {
    flex: 1;
    background-color: #e6f7ff;
    padding: 25px;
    border-radius: 8px;
    text-align: left;
  }
  
  .stepsContainer .heading {
    color: #031450;
    margin-bottom: 15px;
  }
  
  .stepsList {
    list-style-type: none;
    padding-left: 0;
    font-weight: 600;
  }
  
  .stepsList li {
    margin-bottom: 15px;
    font-size: 1.1rem;
    color: #333;
    padding-left: 20px;
    position: relative;
  }
  
  .stepsList li::before {
    content: "✓";
    color: #031450;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .licenseDetails {
    flex: 1;
    padding: 20px;
    border-radius: 8px;
    text-align: left;
  }
  
  .licenseDetails .heading {
    color: #f08080;
    margin-bottom: 15px;
  }
  
  .licenseDetails .subHeading {
    margin-top: 20px;
    color: #161515;
    font-size: 1.2rem;
  }
  
  .licenseList1 {
    list-style-type: none;
    padding-left: 0;
  }
  
  .licenseList li {
    margin-bottom: 10px;
    font-size: 1.1rem;
    color: #333;
  }
  
  .licenseList li strong {
    color: #f08080;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .Section {
      flex-direction: column;
    }
  
    .section1,
    .section2 {
      width: 100%;
    }
  
    .licenseList {
      display: flex;
      flex-direction: column;
    }
  
    .licenseItem {
      margin-bottom: 15px;
    }
  
    .licenseSection {
      flex-direction: column;
      align-items: center;
      padding: 20px;
    }
  
    .stepsContainer,
    .licenseDetails {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .stepsContainer .heading,
    .licenseDetails .heading {
      font-size: 24px;
    }
  }
  
  @media (max-width: 768px) {
    .heading1,
    .heading2 {
      font-size: 24px;
    }
  
    .heading_content {
      font-size: 18px;
    }
  
    .popupContent {
      width: 90%;
      max-width: 500px;
    }
  
    .stepsContainer,
    .licenseDetails {
      padding: 15px;
    }
  
    .stepsList li {
      font-size: 1rem;
    }
  
    .stepsContainer .heading {
      font-size: 22px;
    }
  }
  
  @media (max-width: 480px) {
    .contain {
      padding: 0 10px;
    }

    .section1 {
      margin-top: 0;
    }
    
    .heading1,
    .heading2 {
      font-size: 22px;
    }
  
    .heading_content {
      font-size: 16px;
    }
  
    .stepsList li {
      font-size: 0.9rem;
    }
  
    .calculateButton {
      padding: 8px 15px;
      font-size: 14px;
    }
  
    .popupContent {
      width: 95%;
      max-width: 400px;
    }
  
    .licenseSection {
      flex-direction: column;
      padding: 15px;
    }
  
    .stepsContainer,
    .licenseDetails {
      width: 100%;
    }
  }
  