.container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-between;
    padding: 0 30px;
    margin-right: 20px;
    margin-left: 20px;
}

.content {
    padding: 30px;
    overflow-y: auto;
    height: auto;
    flex: 3;
    background: linear-gradient(135deg, #ffffff, #ffffff);
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    margin-bottom: 30px;
    background-color: #f1f8e9;
}

.content:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    transform: translateY(-5px);
}

.sectionTitle {
    font-size: 35px;
    font-weight: 700;
    color: #00695c;
    text-align: center;
    margin-bottom: 20px;
}

.resourceCard {
    margin-bottom: 25px;
    padding: 25px;
    border: none;
    border-radius: 15px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.resourceCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    background-color: #e0f7fa;
}

.resourceCard h3 {
    font-size: 22px;
    color: #00695c;
    font-weight: 600;
    text-align: center;
}

.resourceCard .description {
    font-size: 16px;
    margin-top: 10px;
    color: #555;
}

.stickyForm {
    position: sticky;
    top: 30px;
    background: #ffffff;
    padding: 30px;
    border: 1px solid #ddd;
    border-radius: 15px;
    flex: 1;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
    margin-top: 20px;
    max-height: 700px;
    background-color: #e8f5e9;
}

.stickyForm .formTitle {
    text-align: center;
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: bold;
    color: #00695c;
}

.stickyForm .form-group {
    margin-bottom: 15px;
}

.stickyForm .submitButton {
    width: 100%;
    margin-top: 10px;
    background-color: #4caf50;
    border: none;
    color: white;
    padding: 12px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
}

.stickyForm .submitButton:hover {
    background-color: #388e3c;
}

.stickyForm select {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    font-size: 16px;
    color: #555;
    box-sizing: border-box;
    margin-top: 10px;
}

.stickyForm select:hover {
    border-color: #4caf50;
}

.faqSection {
    margin-top: 40px;
    background: #fafafa;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #e0f7fa;
}

.faqSection h3 {
    font-size: 26px;
    font-weight: 700;
    color: #00695c;
    margin-bottom: 20px;
    text-align: center;
}

.faqCard {
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 15px;
    background-color: #ffffff;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.faqCard:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.faqCard h4 {
    font-size: 20px;
    color: #00695c;
    font-weight: 600;
}

.faqCard p {
    font-size: 16px;
    color: #555;
    margin-top: 10px;
}

.faqCard:hover h4 {
    color: #388e3c;
}

.custom-select {
    position: relative;
}

.custom-select select {
    padding-left: 30px;
    padding-right: 40px;
    appearance: none;
    background-color: #fff;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
    color: #555;
    cursor: pointer;
}

.dropdownInput {
    position: relative;
}

.dropdownIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #00695c;
    pointer-events: none;
    z-index: 1;
}

.dropdown select:hover {
    border-color: #4caf50;
}

.dropdown select:focus {
    outline: none;
    border-color: #4caf50;
}

.dropdown select:focus + .dropdownIcon {
    color: #4caf50;
}


.popup {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    width: 400px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    text-align: center;
    z-index: 1000;
  }
  
  .closeButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }

  

/* 1440px and above */
@media only screen and (max-width: 1440px) {
    .container {
        gap: 20px;
    }

    .content {
        padding: 25px;
    }

    .resourceCard {
        padding: 20px;
    }

    .stickyForm {
        padding: 25px;
    }

    .faqSection {
        padding: 25px;
    }
}

/* 1024px and below (Tablet) */
@media only screen and (max-width: 1024px) {
    .container {
        flex-direction: column;
        align-items: center;
        padding: 0 15px;
    }

    .content {
        padding: 20px;
        width: 100%;
    }

    .resourceCard {
        width: 100%;
        padding: 20px;
    }

    .stickyForm {
        width: 100%;
        padding: 20px;
    }

    .faqSection {
        padding: 20px;
    }
}

/* 768px and below (Tablet in Portrait) */
@media only screen and (max-width: 768px) {
    .container {
        padding: 0 10px;
    }

    .content {
        padding: 15px;
    }

    .resourceCard {
        width: 100%;
        padding: 15px;
    }

    .stickyForm {
        padding: 15px;
    }

    .faqSection {
        padding: 15px;
    }

    .sectionTitle {
        font-size: 28px;
    }

    .resourceCard h3 {
        font-size: 18px;
    }
}

/* 425px and below (Mobile) */
@media only screen and (max-width: 425px) {
    .container {
        padding: 0 15px;
    }

    .content {
        padding: 15px;
        width: 100%;
    }

    .resourceCard {
        width: 100%;
        padding: 15px;
        margin-bottom: 20px;
        border-radius: 12px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .stickyForm {
        padding: 15px;
        width: 100%;
        max-width: 100%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .faqSection {
        padding: 15px;
        margin-top: 20px;
    }

    .sectionTitle {
        font-size: 24px;
        text-align: center;
        margin-bottom: 20px;
    }

    .resourceCard h3 {
        font-size: 18px;
        text-align: left;
    }

    .faqCard h4 {
        font-size: 18px;
        color: #00695c;
    }

    .faqCard p {
        font-size: 14px;
    }

    .stickyForm select, .stickyForm .submitButton {
        width: 100%;
        padding: 12px;
    }
}
