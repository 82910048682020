.container{
    margin-top: 150px;
    margin-bottom: 150px;
}
.box{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-top: 7px;
    margin-bottom: 60px;
    padding-top: 19px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    border-radius: 15px;
    cursor: pointer;

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.head{
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: 500;
    color: #9951c4;
}
.subHead{
    font-family: 'Poppins';
    font-size: 42px;
    font-weight: bold;
    color: #222a41;
    margin-bottom: 60px;
}

.img{
    width: 60px;
    height: auto;
    border-radius: 50px;
}
.name{
    font-size: 20px;
    font-family: 'Poppins';
    text-align: left;
    margin-bottom: 0px;
    color: #222a41;
    font-weight: bold;
}
.profession{
    font-family: 'Poppins';
    font-size: 16px;
    text-align: left;
    color: #222a41;
}
.msg{
    font-family: 'Poppins';
    text-align: left;
    color: #222a41;
    font-size: 15px;
    padding-top: 5px;
}

@media screen and (max-width: 1200px) {
    .head{
        font-size: 25px;
    }
    .subHead{
        font-size: 40px;
    }
    .name{
        font-size: 19px;
    }
    .profession{
        font-size: 15px;
    }
    .msg{
        font-size: 14px;
    }
    .img{
        width: 55px;
    }
}
@media screen and (max-width: 810px) {
    .head{
        font-size: 22px;
    }
    .subHead{
        font-size: 32px;
        margin-bottom: 60px;
    }
    .container{
        margin-bottom: 100px;
        margin-top: 100px;
    }
    .msg{
        padding-top: 10px;
    }
}

@media screen and (max-width: 575px) {
    .name{
        text-align: center;
        margin-top: 10px;
    }
    .profession{
        text-align: center;
    }
    .msg{
        text-align: justify;
    }
    
}