.contain {
  margin-top: 130px;
  margin-bottom: 130px;
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.heading {
  font-size: 40px;
  color: #9951c4;
  font-weight: 500;
}

.serviceContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 30px;
  gap: 30px;
}

.imageContainer {
  width: 50%;
  padding-right: 20px;
}

.image {
  width: 80%;
  height: auto;
}

.contentContainer {
  width: 50%;
}

.contentHeading {
  margin-top: 120px;
  font-size: 20px;
  font-weight: 500;
}

@media screen and (max-width: 1200px) {
  .contain {
    margin-top: 80px;
    margin-bottom: 80px;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .heading {
    font-size: 35px;
  }

  .contentHeading {
    margin-top: 60px;
    font-size: 18px;
    font-weight: 500;
  }

}

@media screen and (max-width: 770px) {

  .heading {
    font-size: 30px;
  }

  .contentHeading {
    margin-top: 0;
    font-size: 16px;
    font-weight: 500;
  }
}

@media screen and (max-width: 430px) {

  .heading {
    font-size: 22px;
  }

  .contain {
    margin-top: 80px;
    margin-bottom: 100px;
  }
  .contentHeading {
    margin-top: 0;
    font-size: 16px;
    font-weight: 500;
  }

}

@media screen and (max-width: 380px) {
  .contain {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media screen and (max-width: 600px) {
  .serviceContainer {
    flex-direction: column;
    align-items: center;
  }

  .imageContainer, .contentContainer {
    width: 100%;
  }

  .heading {
    font-size: 35px;
  }

  .contentHeading {
    margin-top: 0;
    font-size: 16px;
    font-weight: 500;
  }
}
