.contain {
    margin-top: 83px;
    margin-bottom: 174px;
}

.cont {
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 20px;
}

.heading {
    font-size: 30px;
    color: #9951c4;
    font-weight: 600;
}

.subHeading {
    font-size: 34px;
    font-weight: bold;
    color: #222a41;
    width: 645px;
}

.serviceImageContainer {
    text-align: center;
    margin-top: 20px;
  }
  
  .serviceImage {
    width: 70%; 
    max-width: 500px;
    height: auto; 
    border-radius: 8px;
    margin-bottom: 30px;
  }
  


.card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    margin-bottom: 20px;
}

.card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-body {
    padding: 20px;
}

.card-title {
    font-size: 20px;
    color: #222a41;
    font-weight: bold;
}

.card-text {
    font-size: 16px;
    color: #666;
}

@media screen and (max-width: 1200px) {
    .cont {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media screen and (max-width: 770px) {
    .subHeading {
        font-size: 25px;
        width: auto;
    }

    .heading {
        font-size: 20px;
    }
}

@media screen and (max-width: 430px) {
    .subHeading {
        font-size: 20px;
    }

    .heading {
        font-size: 17px;
    }

    .contain {
        margin-top: 80px;
        margin-bottom: 100px;
    }
}

@media screen and (max-width: 380px) {
    .cont {
        padding-left: 10px;
        padding-right: 10px;
    }
}
