.container {
  max-width: 800px;
  margin: 50px auto;
  background: #fff;
  padding: 80px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.heading {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.label {
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
  display: block;
  text-align: left;
}

.phoneWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.input,
.mobile-country-code {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box; 
}

.mobile-country-code {
  width: 120px; 
}

.formGroup {
  margin-bottom: 20px;
}

.input:focus,
.mobile-country-code:focus {
  border-color: #5e99b8; 
  outline: none;
}

.input::placeholder,
.mobile-country-code::placeholder {
  color: #888;
}

.phoneWrapper select {
  background-color: #f9f9f9; 
}

.phoneWrapper input {
  background-color: #fff; 
}


.radioLabel {
  margin-left: 5px;
  margin-right: 20px;
  color: #555;
}

.btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn:hover {
  background-color: #0056b3;
}

.buttons {
  text-align: center;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.result {
  margin-top: 30px;
  padding: 15px;
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.result h3 {
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: #333;
}

.result p {
  font-size: 1.2rem;
  color: #555;
}

.formGroup {
  flex: 1;
}

.row .gap-4 {
  gap: 1rem;
}

.field-row {
  margin-bottom: 20px;
}
