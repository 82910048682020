.head {
  font-size: 30px;
  font-weight: 600;
  color: #9951c4;
  text-align: left;
  margin-bottom: 20px;
  letter-spacing: normal;
}

.heading {
  font-size: 50px;
  color: #222a41;
  font-weight: 600;
  text-align: left;
  margin-bottom: 34px;
  width: 90%;
  line-height: 1.3;
  transition: color 0.3s ease;
  letter-spacing: normal;
}

.heading:hover {
  color: #9951c4;
}

.content {
  font-size: 19px;
  color: #222a41;
  text-align: left;
  margin-bottom: 36px;
  text-align: justify;
  letter-spacing: normal;
}

.btn {
  width: 270px;
  height: 64px;
}

.contain {
  margin-top: 143px;
  margin-bottom: 143px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
  padding: 0 8px;
  margin-top: 65px;
}

.column {
  flex: 1;
  padding: 0 8px;
}

.feature-card {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.featuresContainer {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  margin-left: 3rem;
  gap: 0;
}

.featureCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem;
  border-radius: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.featureCard:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.mb6 {
  margin-bottom: 1.5rem;
}

.iconContainer {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: var(--dimBlue);
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 50%;
  height: 50%;
  object-fit: contain;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 70%;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 0.25rem;
}

.content {
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
  width: 100%;
  letter-spacing: 0;
}

.column img {
  margin-top: 30px;
  width: 100%;
  border-radius: 15px;
}

@media screen and (max-width: 1200px) {
  .heading {
    font-size: 40px;
  }

  .row {
    padding: 0px 6px;
    margin-top: 85px;
  }

  .column img {
    margin-top: 11px;
  }
}

@media screen and (max-width: 991px) {
  .head {
    font-size: 25px;
  }

  .heading {
    width: 100%;
  }

  .row {
    margin-top: 110px;
  }
}

@media screen and (max-width: 768px) {
  .row {
    margin-top: 50px;
    justify-content: center;
  }

  .heading,
  .content {
    letter-spacing: normal;
  }


  .featuresContainer {
    margin-left: 0;
  }
}

@media screen and (max-width: 500px) {
  .head {
    font-size: 24px;
  }

  .heading {
    font-size: 34px;
  }

  .btn {
    width: 200px;
    height: 54px;
  }

  .row {
    padding: 0px 4px;
    margin-top: 20px;
  }

  .content {
    letter-spacing: 0;
  }
  
  .featuresContainer {
    margin-left: 0;
  }
}

@media screen and (max-width: 428px) {
  .col1 {
    margin-top: 60px;
  }

  .contain {
    margin-bottom: 80px;
    margin-top: 80px;
  }
}

@media screen and (max-width: 340px) {
  .col1 {
    margin-top: 40px;
  }
}
