@import url("https://fonts.googleapis.com/css2?family=Newsreader:wght@400;600&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css");

.dropicon {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #181717;
  font-weight: 600;
  transition: color 0.3s ease, transform 0.3s ease;
}

.dropicon i {
  transition: transform 0.3s ease;
}

.drop:hover .dropdown-menu {
  display: block !important;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in;
}

.dropdown-menu {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.2s, opacity 0.2s linear;
}

.drop:hover .dropicon i {
  transform: rotate(180deg);
}

.navLinks {
  margin-left: auto;
  display: flex;
  gap: 15px;
  flex-wrap: nowrap;
  padding: 10px 0;
}

.nav_text {
  font-size: 19px;
  color: #111111 !important;
  font-family: "Newsreader", serif;
  transition: all 0.3s ease;
  padding: 10px 0;
}

.nav_text:hover {
  padding-top: 6px;
  color: #8a2be2 !important;
  font-weight: bold;
  text-decoration: none;
}

.active {
  padding-top: 6px !important;
  color: #8a2be2 !important;
  font-weight: bold;
}

.drop {
  width: auto;
  transition: all 0.3s ease;
  padding: 10px 0;
}

.drop:hover .nav_text {
  color: #8a2be2 !important;
  font-weight: bold;
  cursor: pointer;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px 0;
}

.logoImage {
  height: 50px;
  margin-right: 10px;
  vertical-align: middle;
}

.logo {
  color: #b5208c !important;
  font-weight: 600;
  font-size: 35px;
  text-align: start;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  font-family: "Newsreader", serif;
  margin-right: 10px;
}

.subLogo {
  font-size: 15px;
  color: #111111;
  font-weight: normal;
  text-align: start;
  text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.2);
  font-family: "Poppins", serif;
  margin-top: -10px;
}

.nav-link:hover .subDropdown {
  display: block;
  visibility: visible;
}

.logoImage {
  height: 50px;
  margin-right: 5px;
  vertical-align: middle;
}

.subDropdown {
  position: absolute;
  left: 100%;
  top: 0;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  padding: 15px;
  border-radius: 5px;
  z-index: 1000;
}

.dropdownText {
  color: #333;
  text-decoration: none;
  padding: 8px 12px;
  display: block;
  border-radius: 5px;
  transition: background-color 0.3s ease, padding-left 0.3s ease;
}

.dropdownText:hover {
  background-color: #f1f1f1;
  padding-left: 20px;
}

.costCalculation {
  font-size: 18px;
  padding: 5px 10px;
  border: 3px solid #8a2be2;
  border-radius: 4px;
  text-align: center;
  transition: all 0.3s ease;
  width: auto;
  height: auto;
}

.costCalculation:hover {
  background-color: #8a2be2;
  color: white !important;
  transform: scale(1.05);
}

@media screen and (max-width: 991px) {
  .navLinks {
    flex-wrap: wrap;
    justify-content: center;
    gap: 6px;
    padding: 10px 0;
  }

  .dropdown-menu {
    display: none !important;
  }

  .dropdown-toggle[aria-expanded="true"] + .dropdown-menu {
    display: block !important;
  }

  .nav_text {
    font-size: 14px;
    padding-top: 10px;
  }

  .drop {
    width: auto;
  }

  .logo {
    font-size: 28px;
  }
}

@media screen and (max-width: 768px) {
  .navLinks {
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    gap: 6px;
  }

  .nav_text {
    font-size: 14px;
  }

  .logoContainer {
    justify-content: center;
    padding: 10px 0;
  }

  .logo {
    font-size: 26px;
    text-align: start;
  }

  .subLogo {
    font-size: 14px;
    text-align: start;
  }

  .costCalculation {
    padding: 6px 12px;
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .logo {
    font-size: 24px !important;
    text-align: start;
  }

  .subLogo {
    font-size: 13px;
  }

  .navLinks {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    gap: 6px;
  }

  .nav_text {
    font-size: 14px;
  }
  .costCalculation {
    font-size: 15px;
    padding: 6px 12px;
  }
}

@media screen and (max-width: 1024px) {
  .navLinks {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 5px;
    padding: 10px;
  }

  .nav_text {
    font-size: 14px;
    color: #555;
    padding: 5px 10px;
    font-family: "Newsreader", serif;
    transition: color 0.3s ease, transform 0.3s ease;
  }

  .nav_text:hover {
    color: #8a2be2;
    transform: scale(1.1);
    font-weight: bold;
  }

  .subLogo {
    font-size: 13px;
    color: #111111;
    font-weight: normal;
    text-align: start;
    text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.2);
    font-family: "Poppins", serif;
    margin-top: -10px;
  }

  .logo {
    color: #b5208c !important;
    font-weight: 500;
    font-size: 25px;
    text-align: start;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    font-family: "Newsreader", serif;
    margin-right: 10px;
  }

  .dropdown-menu {
    display: none;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 10px;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .drop:hover .dropdown-menu {
    display: block;
    visibility: visible;
    opacity: 1;
  }

  .dropdownText {
    font-size: 14px;
    color: #333;
    padding: 10px;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.3s ease, padding-left 0.3s ease;
  }

  .dropdownText:hover {
    background-color: #f4f4f4;
    padding-left: 20px;
  }

  .costCalculation {
    font-size: 12px;
    padding: 2px 2px;
    border: 2px solid #8a2be2;
    border-radius: 2px;
    text-align: center;
    transition: all 0.3s ease;
    width: auto;
    height: auto;
  }

  .costCalculation:hover {
    background-color: #8a2be2;
    color: white !important;
    transform: scale(1.02);
  }
}
